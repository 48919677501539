





















































































































import { Component, Ref, Vue } from "vue-property-decorator";
import {
  createBlindBoxOrder,
  getBlindBoxDetail,
  loginPhone,
  sendCode,
} from "@/api/activity.blind-box.api";
import { countdown, getChance, getNow } from "@/utils/common.util";
import SupplyPackageApi, {
  ISupplyBox,
  ISupplyCardItem,
  ISupplyDetail,
} from "@/api/supply-package.api";
import DestinyCard from "@/views/destiny-draw/components/destinyCard/DestinyCard.vue";
import { Toast, Popup } from "vant";
import { getModule } from "vuex-module-decorators";
import GlobalStateStore from "@/store/modules/global-state.store";
import { TOKEN_NAME, PHONE_NAME } from "@/constant";
import { dynamicImportScript } from "@/utils/script.util";

@Component({
  components: {
    Popup,
    DestinyCard,
  },
})
export default class BlindBoxDetail extends Vue {
  private cardWideHigh = 120;
  private private_id = this.$route.params?.private_id;
  private phoneStr = this.$route.params?.phone;
  private codeStr = "";
  private downTime = getNow() + 15 * 60;
  private downText = "请在15分00秒内完成支付";
  private timeout = 0;
  private captcha: any;
  private smallItems: Array<ISupplyCardItem> = [];
  private items: Array<ISupplyBox> = [];
  private data: ISupplyDetail | null = null;
  private smsCode = 0;
  private timeKey = "activity-blind-box";
  private globalState = getModule(GlobalStateStore);
  private showCardList = false;
  private btnShow = true;
  private docHeight =
    document.documentElement.clientHeight || document.body.clientHeight;
  private showHeight =
    document.documentElement.clientHeight || document.body.clientHeight;

  switchShowCard() {
    this.showCardList = !this.showCardList;
    this.$nextTick(() => {
      this.cardWideHigh = this.calculativeWidth();
    });
  }

  get isLogin() {
    return !!this.globalState.userToken;
  }

  get cardList(): ISupplyCardItem[] {
    return this.items
      .map((row) => {
        return {
          id: row.id,
          product_bazaar_price: row.product_bazaar_price,
          cover: row.cover,
          character: row.level,
          title: row.title,
          price: row.price / 100,
          rate: `爆率 ${getChance(row.chance)}%`,
        };
      })
      .sort((a, b) => b.character - a.character);
  }

  get phoneVar() {
    const phone: string[] = [...String(this.phoneStr)];
    return `${phone.slice(0, 3).join("")} ${phone.slice(3, 7).join("")} ${phone
      .slice(7, 11)
      .join("")}`;
  }

  private handleSendCode() {
    if (this.smsCode > 0) return;
    this.captcha.show(); // 展示验证码功能, 比如用户点击获取验证码这个时候调用这个方法
  }

  private handleSendCodeCountDown() {
    if (this.smsCode <= 0) {
      this.smsCode = 60;
      localStorage.setItem(this.timeKey, String(getNow()));
    }
    const timeout = setInterval(() => {
      this.smsCode--;
      if (this.smsCode <= 0) {
        this.smsCode = 0;
        clearInterval(timeout);
      }
    }, 1000);
  }

  private async dynamicImportTCaptchaJs() {
    const script = document.createElement("script");
    script.src = "https://ssl.captcha.qq.com/TCaptcha.js";
    document.querySelector("head")?.appendChild(script);
    let i = 0;
    const interval = setInterval(() => {
      if (i * 100 > 10000) {
        clearInterval(interval);
        return;
      }
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if (window["TencentCaptcha"]) {
        clearInterval(interval);
        this.captcha = new TencentCaptcha(
          "2033952729",
          async ({ ticket, randstr }: any) => {
            const row = await sendCode(this.phoneStr, ticket, randstr);
            if (row.Message === "OK") this.handleSendCodeCountDown();
            else Toast("短信异常");
          }
        );
        return;
      }
      i++;
    }, 100);
  }

  private handleCountDown() {
    const { minute, second } = countdown(this.downTime);
    if (minute == "00" && second == "00") {
      clearInterval(this.timeout);
      this.$router.push({
        name: "BlindBoxActivityPrivateId",
        params: { private_id: this.private_id },
      });
    }
    return `请在${minute}分${second}秒内完成支付`;
  }

  private async getBlindBoxInfo() {
    this.data = await getBlindBoxDetail(this.private_id);
    document.title = this.data.title;
    this.items = await SupplyPackageApi.getSupplyBoxList(this.data.id);
    this.smallItems = this.cardList.slice(0, 5);
  }

  calculativeWidth(): number {
    const __feeds = document.querySelector(".__feed_list");
    const width = __feeds?.clientWidth || __feeds?.scrollWidth || 0;
    return width / 3 - 8;
  }

  private mounted() {
    const track_id = localStorage.getItem("track_id");
    const channel = localStorage.getItem("channel");
    if (!track_id || track_id == "undefined") {
      localStorage.setItem(
        "track_id",
        String(this.$route.query?.track_id || "") || ""
      );
    }
    if (!channel || channel == "undefined") {
      localStorage.setItem(
        "channel",
        String(this.$route.query?.channel || "") || ""
      );
    }
    this.cardWideHigh = this.calculativeWidth();
    let token = localStorage.getItem(TOKEN_NAME);
    const phone = localStorage.getItem(PHONE_NAME);
    if (!phone || this.phoneStr !== phone) {
      this.globalState.setUserToken(null);
      token = null;
    }
    if (token) this.globalState.setUserToken(token);
    const time = +(localStorage.getItem(this.timeKey) || 0);
    if (time + 60 > getNow()) {
      this.smsCode = time + 60 - getNow();
      this.handleSendCodeCountDown();
    }
    this.timeout = setInterval(() => {
      this.downText = this.handleCountDown();
    }, 1000);
    this.dynamicImportTCaptchaJs();
    this.getBlindBoxInfo();
  }

  private async handleStartPayment() {
    const toast = Toast.loading("处理中...");
    const row = await createBlindBoxOrder(
      this.data!.id,
      5,
      `${location.origin}/activity/box-result/${this.private_id}${location.search}`
    ).catch((err) => {
      Toast(err.message);
      toast.clear();
      throw err;
    });
    if (row?.alipay) {
      // const form = document.createElement("form");
      // form.action = row?.alipay || "";
      // form.method = "post";
      // form.target = "_blank";
      // form.style.display = "none";
      // document.body.appendChild(form);
      // form.submit();
      location.href = row?.alipay;
    }
    toast.clear();
  }

  private async handlePayment() {
    if (this.isLogin) {
      await this.handleStartPayment();
      return;
    }
    if (!this.codeStr) {
      Toast("请输入正确的验证码");
      return;
    }
    const toast = Toast.loading("处理中...");
    const row = await loginPhone(this.phoneStr, this.codeStr).catch((err) => {
      toast.clear();
      Toast(err.message);
      throw err;
    });
    toast.clear();
    localStorage.setItem(PHONE_NAME, this.phoneStr);
    this.globalState.setUserToken(row.token);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    return this.handleStartPayment();
  }
}
